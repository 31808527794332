<template>
    <div class="calendar_box">
        <el-row :gutter="20">
            <el-col :span="18">
                <full-calendar ref="fullCalendar" style="height: 100%" :options="calendarOptions"></full-calendar>
            </el-col>
            <!--<el-col :span="6" class="detail_box">
                <div class="addbtn">
                    <el-button type="primary"  size="small">添加排班</el-button>
                </div>
                <el-alert title="排班信息" type="success" :closable="false"> </el-alert>
                <el-form :model="form" label-width="100px">
                    <el-form-item label="排班标题：" >
                        {{form.title}}
                    </el-form-item>
                    <el-form-item label="科室：" >
                        {{form.ksName}}
                    </el-form-item>
                    <el-form-item label="出诊医生：" >
                        {{form.doctorName}}
                    </el-form-item>
                    <el-form-item label="联系电话：" >
                        {{form.phone}}
                    </el-form-item>
                    <el-form-item label="出诊时间：" >
                        {{form.ctime}}
                    </el-form-item>
                    <div class="addbtn" style="text-align: right;margin-right: 20px;">
                        <el-button type="primary"  size="small" v-show="isShowBtn">修改</el-button>
                        <el-button type="danger" size="small" v-show="isShowBtn">删除</el-button>
                    </div>
                </el-form>
            </el-col> -->
        </el-row>  
        
        <!-- 添加或修改 -->
        <el-dialog title="编辑排班" :visible.sync="dialogFormVisible">
            <el-form :model="form" label-width="80px">
                <el-form-item label="日期" >
                    <el-input v-model="form.title"  placeholder="" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="排班类型" >
                     <el-select v-model="form.woryType" placeholder="请选择排班类型">
                        <el-option
                            v-for="item in form.workTypeList"
                            :key="item.workType"
                            :label="item.text"
                            :value="item.workType"
                            @click.native = scheduleSave(form.title,item.workType)
                            >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">

            </div>
        </el-dialog>

   </div>
</template>

<script>
import { scheduleList , setSchedule } from "@/api/staff";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from 'axios'
 export default {
    name:'FindPaiban',
    components:{FullCalendar},
    data() {
        return {
            staffId:'',
            shopId:'',
            isShowBtn: false,
            dialogFormVisible:false,
            form:{
                //workTypeList:new Date(),
                id:"",
                title:"",
                ctime:'',
                className:"",
                workTypeList:[
                    {
                        workType:0,
                        text:"休"
                    },
                    {
                        workType:1,
                        text:"全天班"
                    },
                    {
                        workType:2,
                        text:"上午班"
                    },
                    {
                        workType:3,
                        text:"下午班"
                    }
                ],
            },
            formLabelWidth:120,
            calendarOptions: {
                    //   timeGridPlugin  可显示每日时间段
                    height: 600,
                    plugins: [dayGridPlugin, interactionPlugin],
                    headerToolbar: {
                    left: "prev",
                    center: "title",
                    right: "next",
                    // right: 'agendaWeek,dayGridWeek,dayGrid'
                },
                buttonText: {
                    // 设置按钮
                    today: "今天",
                    month: "月",
                    week: "周",
                    dayGrid: "天",
                },
                // allDaySlot: false,
                editable: true,
                selectable: true,
                navLinks: true,
                // displayEventEnd: true,//所有视图显示结束时间
                initialView: "dayGridMonth", // 设置默认显示月，可选周、日
                //dateClick: this.handleEventClick,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                select: this.handleDateSelect,
                // timezone
                // 设置日程
                events: [],
                //events格式如下
                 // {
		          // 	id: 1,
		          // 	title: '09:30~11:30 小破孩',
		          // 	start: '2020-12-11',
		          // 	end: '2020-12-13',
		          // 	color: '#f08f00',
		          // },
		          // {
		          // 	id: 2,
		          // 	title: '9:30~14:30 项目会议',
		          // 	start: '2020-12-14',
		          // 	end: '2020-12-14',
		          // 	color: '#6bb377',

                eventColor: "#f08f00", // 修改日程背景色
                locale: "zh-cn", // 设置语言
                weekNumberCalculation: "ISO", // 周数
                customButtons: {}
            },
            doctorList:[]
        };
    },
    mounted(){
        this.getPaiban();
        //this.getAllDoctor()
    },
    methods: {
        //获取排班
        getPaiban(){
            //console.log(this.id);
            scheduleList({ staffIds: [this.$route.query.id] }).then((res) => {
                //console.log(res.data.data);
                //this.list = res.data.data;
                this.name = res.data.data.name;
                this.staffId = this.$route.query.id;
                this.shopId = this.$route.query.shop_id
                //this.year = res.data.data.year;
                //res.data.data.splice('id',1);
                //res.data.data.splice('id',1);
                //res.data.data.splice('year',1);
                var result = new Array();
                //result = res.data.data[0];
                //console.log(result);
                Object.keys(res.data.data[0]).forEach(function (key) {
                    if(key != "id" && key != "name" && key != "year")
                        {
                            var arr = new Array();
                            arr.start=res.data.data[0]['year']+'-'+key;
                            //console.log(res.data.data[0][key]);
                            switch(res.data.data[0][key]){
                                case '0':{
                                
                                    arr.textColor = 'white';
                                    arr.color = 'ForestGreen';
                                    arr.title = "休";
                                    arr.className = "eventone";
                                    break;
                                }
                                case '1':{
                    
                                    arr.textColor = '#6b849d';
                                    arr.color = '#dfe2e9';
                                    arr.title = "全天班";
                                    arr.className = "eventone";
                                    break;
                                }
                                case '2':{
                                    arr.textColor = 'white';
                                    arr.color = '#2bada8';
                                    arr.title = "上午班";
                                    break;
                                }
                                case '3':{
                      
                                    arr.textColor = 'white';
                                    arr.color = '#c96bc2';
                                    arr.title = "下午班";
                                    break;
                                }
                            }
                            result.push(arr);
                            //arr.doctorName = d.doctorName;
                            //arr.ksName = d.ksName;
                            //arr.phone = d.phone;
                            //res.data.data[0][key]
                        }

                });

                this.calendarOptions.events=result;
            })
            
        },
        //保存
        //点击排班事件
        handleEventClick(calEvent){
            this.isShowBtn = true;
            var data = calEvent.event;
            //this.form.id = data._def.extendedProps.pId,
            //this.form.doctorId = data._def.extendedProps.doctorId,
            this.form.title = calEvent.event.startStr,
            console.log(calEvent.event);
            //this.form.ctime = data._def.extendedProps.pbDate.substring(0,10),
            //this.form.doctorName = data._def.extendedProps.doctorName,
            //this.form.ksName = data._def.extendedProps.ksName,
            //this.form.phone = data._def.extendedProps.phone,
            switch(calEvent.event.title){
                case "全天班":{
                    this.form.woryType = 1;
                    break;
                }
                case "上午班":{
                    this.form.woryType = 2;
                    break;
                }
                case "下午班":{
                    this.form.woryType = 3;
                    break;
                }
                case "休":{
                    this.form.woryType = 0;
                    break;
                }

            }


            this.dialogFormVisible = true
        },
        //删除
        dateToString(now){
            var year = now.getFullYear();
            var month =(now.getMonth() + 1).toString();
            var day = (now.getDate()).toString();
            if (month.length == 1) {
                month = "0" + month;
            }
            if (day.length == 1) {
                day = "0" + day;
            }
            var dateTime = year + "-" + month + "-" + day;
            return dateTime;
        },
        denglu(li){
            let fd = new FormData (document.getElementById("loginform")) ;
            this.ajax.request({
                url:'/yonghu/login.action',
                data:fd,
                method:'post'
            }).then((res)=>{
                if(!res.data.errorcode){

                }
            }).catch(() => {
        
            })
        },
        scheduleSave(date,workType)
        {

            //this.form.woryType = woryType;
            var is_work='';
            var work_type = '';
            switch(workType)
            {
                case 0:{
                    is_work=0;
                    work_type=0;
                    break;
                }
                case 1:{
                    is_work=1;
                    work_type=0;
                    break;
                }
                case 2:{
                    is_work=1;
                    work_type=1;
                    break;
                }
                case 3:{
                    is_work=1;
                    work_type=2;
                    break;
                }
            }
            setSchedule({
                shop_id:this.shopId,
                id:this.staffId,
                year:date.slice(0,4),
                data:[
                    {
                        date:date.slice(5),
                        is_work:is_work,
                        work_type:work_type
                    }
                ]
            }).then((res) => {
                if(res.code != 200){
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                }
                console.log(res);
                this.getPaiban();
            })
            //console.log(param);
            this.dialogFormVisible = false;
        },
    }
}
</script>

<style scoped>

.addbtn{
    margin-top:15px;
    text-align: left;
    margin-bottom:15px;
}
.calendar_box>>>.el-dialog{
    width: 450px;
}
.calendar_box>>>.el-date-editor.el-input{
    width: 330px;
}
.el-select{
    width: 100%;
}
.detail_box>>>.el-form{
    border:1px solid #ddd;
}
.detail_box>>>.el-form-item__content{
    text-align: left;
    color: #333;
}
.eventone{
    color: #333;
}
</style>

